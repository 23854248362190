import { AxiosHelper } from '..';

export const MS_PHASE_2C_PIM_PSS_ENPOINT = {
  LIST_PIM_CENTRE_DETAILS: '/pim-centre/list-pim-centre-details',
  GET_PIM_CENTRE_DETAILS: '/pim-centre/get-pim-centre-details',
  GET_PARENT_SURVEY: '/satisfaction-survey/get-survey-for-parent',
  SURVEY_NOTIFICATION_CONFIG:
    '/satisfaction-survey/get-survey-notification-config',
  SURVEY_DETAILS_FROM_SURVEY_UUID: '/satisfaction-survey/get-survey-details',
  GET_SURVEY_CONFIG: '/satisfaction-survey/get-survey-config',
  GET_SURVEY_QUESTIONS: '/satisfaction-survey/get-survey-questions',
  UPDATE_SURVEY_RESPONSE: '/satisfaction-survey/update-survey-response',
  GET_SURVEY_PROGRESS: '/satisfaction-survey/get-survey-progress',
  UPDATE_PIM_CENTRE_DETAILS: '/pim-centre/update-pim-centre-details',
  UPDATE_PIM_CENTRE_DETAILS_BULK: '/pim-centre/update-pim-centre-details-bulk',
  GET_SURVEY_YEARS: '/satisfaction-survey/get-survey-years',
};

export const parentSurveyService = authToken =>
  AxiosHelper({
    baseURL: process.env.REACT_APP_MS_PSS_SVC_URL,
    ...(authToken && {
      authToken,
    }),
  });

export const parentInteractionService = () =>
  AxiosHelper({ baseURL: process.env.REACT_APP_MS_PIM_SVC_URL });
