import { parseGraphQLError, actionHandlerWithFeatureFlag } from 'utils';
import get from 'lodash/get';
import { SkApolloRequest } from '../../utils/apolloUtils';

import {
  FETCH_PIM_CENTRE_LIST,
  FETCH_PIM_CENTRE_DETAILS,
  UPDATE_PIM_CENTRE_DETAILS,
  UPDATE_PIM_CENTRE_DETAILS_BULK,
} from '../models/parentInteractionModel';

import {
  FETCH_PIM_CENTRE_LIST_LOADING,
  FETCH_PIM_CENTRE_LIST_SUCCESS,
  FETCH_PIM_CENTRE_LIST_ERROR,
  FETCH_PIM_CENTRE_DETAILS_LOADING,
  FETCH_PIM_CENTRE_DETAILS_SUCCESS,
  FETCH_PIM_CENTRE_DETAILS_ERROR,
} from './actionTypes';

import { DEFAULT_ERROR, FEATURE_FLAGS } from '../../utils/constants';

import {
  MS_PHASE_2C_PIM_PSS_ENPOINT,
  parentInteractionService,
} from '../../utils/ms-utils/Phase2c';

import {
  modifyCentreList,
  modifyCentreDetails,
  prepareUpdatePIMReqBody,
} from '../../utils/ms-utils/Phase2c/HelperPIM';
import DatadogHandler from '../../utils/datadog';

const pimServiceReqBase = parentInteractionService();

const pimCentreListingLoading = () => ({
  type: FETCH_PIM_CENTRE_LIST_LOADING,
  key: 'fetchPIMCentres',
  value: {
    inProgress: true,
  },
});

const pimCentreListingSuccess = data => ({
  type: FETCH_PIM_CENTRE_LIST_SUCCESS,
  key: 'fetchPIMCentres',
  value: {
    inProgress: false,
    data,
  },
});

const pimCentreListingError = error => ({
  type: FETCH_PIM_CENTRE_LIST_ERROR,
  key: 'fetchPIMCentre',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

const pimCentreDetailsLoading = () => ({
  type: FETCH_PIM_CENTRE_DETAILS_LOADING,
  key: 'fetchPIMCentreDetails',
  value: {
    inProgress: true,
  },
});

const pimCentreDetailsSuccess = data => ({
  type: FETCH_PIM_CENTRE_DETAILS_SUCCESS,
  key: 'fetchPIMCentreDetails',
  value: {
    inProgress: false,
    data,
  },
});

const pimCentreDetailsError = error => ({
  type: FETCH_PIM_CENTRE_DETAILS_ERROR,
  key: 'fetchPIMCentreDetails',
  value: {
    inProgress: false,
    data: null,
    error,
  },
});

export const msFetchAllPIMCentres = async (dispatch, reqData) => {
  try {
    const reqParams = {
      full_data: false,
      search_term: reqData?.searchTerm?.NameOrCodeLike,
    };
    const pagination = {
      page: reqData?.pagination?.page,
      perPage: reqData?.pagination?.perPage,
      ...(reqData?.pagination?.customSort && {
        customsort: reqData?.pagination?.customSort,
      }),
    };
    const result = await pimServiceReqBase.post(
      `${
        MS_PHASE_2C_PIM_PSS_ENPOINT.LIST_PIM_CENTRE_DETAILS
      }?${new URLSearchParams(pagination)}`,
      reqParams
    );

    const data = modifyCentreList(result);
    dispatch(pimCentreListingSuccess(data.data));
    return data;
  } catch (ex) {
    dispatch(pimCentreListingError(ex.ex.message));
  }
};

export const monoFetchAllPIMCentres = async (dispatch, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_PIM_CENTRE_LIST,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(pimCentreListingSuccess(data.data));
    } else {
      dispatch(pimCentreListingError(data.error));
    }
  } catch (ex) {
    dispatch(pimCentreListingError(ex.ex.message));
  }
};

export const fetchAllPIMCentres = reqData => async dispatch => {
  dispatch(pimCentreListingLoading());
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      dispatch,
      msHandler: msFetchAllPIMCentres,
      monoHandler: monoFetchAllPIMCentres,
      extraData: reqData,
    }
  );
  return data;
};

export const msGetPIMCentreDetails = async (dispatch, reqData) => {
  try {
    const result = await pimServiceReqBase.get(
      `${MS_PHASE_2C_PIM_PSS_ENPOINT.GET_PIM_CENTRE_DETAILS}/${reqData?.pimCentreID}`
    );

    const data = modifyCentreDetails(result);
    dispatch(pimCentreDetailsSuccess(data?.data));

    return data;
  } catch (ex) {
    dispatch(pimCentreDetailsError(ex.ex.message));
    return {};
  }
};

export const monoGetPIMCentreDetails = async (dispatch, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        query: FETCH_PIM_CENTRE_DETAILS,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      dispatch(pimCentreDetailsSuccess(data.data));
    } else {
      dispatch(pimCentreDetailsError(data.error));
    }
  } catch (ex) {
    dispatch(pimCentreDetailsError(ex.ex.message));
  }
};

export const getPIMCentreDetails = reqData => async dispatch => {
  dispatch(pimCentreDetailsLoading());
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      dispatch,
      msHandler: msGetPIMCentreDetails,
      monoHandler: monoGetPIMCentreDetails,
      extraData: reqData,
    }
  );
  return data;
};

export const msUpdatePIMCentreDetails = async (_, reqData) => {
  const body = prepareUpdatePIMReqBody(reqData);
  try {
    await pimServiceReqBase.put(
      `${MS_PHASE_2C_PIM_PSS_ENPOINT.UPDATE_PIM_CENTRE_DETAILS}`,
      body
    );
    return {
      success: true,
    };
  } catch (ex) {
    const message =
      get(ex, 'response.data.message') || get(ex, 'message') || DEFAULT_ERROR;
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(
      ex,
      {
        reqData: body,
        functionName: 'msUpdatePIMCentreDetails',
        fileName: 'redux/actions/ParentInteraction.js',
      },
      'error'
    );
    return {
      success: false,
      error: [{ message }],
    };
  }
};

export const monoUpdatePIMCentreDetails = async (_, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_PIM_CENTRE_DETAILS,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(
      ex,
      {
        reqData,
        functionName: 'monoUpdatePIMCentreDetails',
        fileName: 'redux/actions/ParentInteraction.js',
      },
      'error'
    );
    return parseGraphQLError(ex);
  }
};

export const updatePIMCentreDetails = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      msHandler: msUpdatePIMCentreDetails,
      monoHandler: monoUpdatePIMCentreDetails,
      extraData: reqData,
    }
  );
  return data;
};

export const msUpdatePIMCentreDetailsBulk = async (_, reqData) => {
  const body = prepareUpdatePIMReqBody(reqData);
  try {
    await pimServiceReqBase.put(
      `${MS_PHASE_2C_PIM_PSS_ENPOINT.UPDATE_PIM_CENTRE_DETAILS_BULK}`,
      body
    );
    return {
      success: true,
    };
  } catch (ex) {
    const message =
      get(ex, 'response.data.message') || get(ex, 'message') || DEFAULT_ERROR;
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(
      ex,
      {
        reqData,
        functionName: 'msUpdatePIMCentreDetailsBulk',
        fileName: 'redux/actions/ParentInteraction.js',
      },
      'error'
    );
    return {
      success: false,
      error: [{ message }],
    };
  }
};

export const monoUpdatePIMCentreDetailsBulk = async (_, reqData) => {
  try {
    const data = await SkApolloRequest({
      params: {
        mutation: UPDATE_PIM_CENTRE_DETAILS_BULK,
        variables: reqData,
      },
      type: 'mutation',
    });
    return data;
  } catch (ex) {
    DatadogHandler.addError(ex);
    DatadogHandler.sendLog(
      ex,
      {
        reqData,
        functionName: 'monoUpdatePIMCentreDetailsBulk',
        fileName: 'redux/actions/ParentInteraction.js',
      },
      'error'
    );
    return parseGraphQLError(ex);
  }
};

export const updatePIMCentreDetailsBulk = reqData => async () => {
  const data = await actionHandlerWithFeatureFlag(
    FEATURE_FLAGS.MS_PHASE_2C_PIM_PSS_DISABLED,
    {
      msHandler: msUpdatePIMCentreDetailsBulk,
      monoHandler: monoUpdatePIMCentreDetailsBulk,
      extraData: reqData,
    }
  );
  return data;
};
